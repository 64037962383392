/* CUSTOM COLOR */
body {overflow-x:hidden;}
.tl-red {
    color: #EE3126;
}

.tl-cyan {
    color: #0B829E;
}
.tl-cyan-light {
    color: #A0B3BE;
}
.bg-tl-red {
    background-color:#EE3126;
}
.border-tl-red {
    border:1px solid #EE3126;
}
.bg-flash-white {
    background-color: #EFF5F5;
}
.bg-footer-slate {
    background-color: #022439;
    width: 100vw;
    /*position: relative;*/
    margin-left: calc(-50vw + 50%);
}
.bg-cyan {
    background-color: #0B829E;
}
.bg-light-blue {
    background-color: #EFF5F5;
    /* width: calc(100vw - 20px);
    margin-left: calc(-50vw + 50% + 10px)*/
    width: calc(100vw);
    margin-left: calc(-50vw + 50%)
}
.bg-light-blue-bar {
    background-color: #EFF5F5;
}
.bg-dark-blue-bar {
    background-color: #022439;
}
.tl-black-900 {
    color:#38383D;
}
.tl-blue-dark {
    color:#022439;
}
.tl-blue-light {
    color: #EFF5F5;
}

.full-length {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
}
.red-arrow {
    border-color: #f00 transparent transparent !important;
}
/*BURGER MENU*/
    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 30px;
        height: 30px;
        right: 10px;
        top: 20px
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #C1C1C1;
        border-radius:5px;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: #EE3126;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #fff;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }


    /* Individual item */
    .bm-item {
        display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
/* TEXT SHADOW */
.text-shadow {
    text-shadow:1px 1px #000;
}
/*SLIDES*/
.each-slide {
    padding:0 10px 0 10px;
}
.indicator.active { color: #B91C1C; }
/*AGENT IMAGE*/
.agent-image {
    min-width: 210px;
    margin-left: -20px;
    object-fit: cover;
}

/*MAP SIDBAR SHADOW*/
.sidebar-shadow {
    box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.75);
}

/* COOKIE CONSENT */
.cc-banner.cc-bottom {
    font-size: 14px;
    text-align: center;
}

.cc-link {
    color: #f5860e !important;
    font-weight: bold;
    font-size: 12px;
}

    .cc-link > p {
        font-size: 12px;
        margin: 0px
    }

.cc-banner .cc-message {
    padding-right: 10px;
}

.cc-window.cc-banner {
    padding: 10px 30px 10px 30px;
}
.hiddenAgent {
    display:none;
}

/*STICKY FILTERS ON AGENT SEARCH*/
.is-sticky {
    position: fixed;
    top: 0px;
    width: 100%;
    animation: fadeIn .5s;
}
.hide-nav {
    display: none;
}
@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
/*END STICKY FILTERS ON AGENT SEARCH*/
/*Z-index on autopopulate drop down on search results*/
#react-select-2-listbox {
    z-index: 9999;
}
@media only screen and (max-width: 768px) {
    .agent-image {
        max-width: 210px;
        min-height:210px;
        max-height:210px;
        object-fit: cover;
        margin: auto;
    }
}
    @media only screen and (max-width: 568px) {
        .cc-window.cc-floating {
            padding: 10px;
            line-height: 15px;
            font-size: 12px;
        }

        .cc-window .cc-message {
            margin-bottom: 5px;
            font-size: 12px;
            line-height: 14px;
        }

        .sidebar-shadow {
            box-shadow: none;
        }

        .hiddenAgent {
            display: block;
        }

        .full-length {
            margin-left: -16px;
            margin-right: -16px;
            position: unset;
            left: calc(-50vw + 50%);
        }
    }