html {
    scroll-behavior: smooth;
    font-family: 'Lato', Arial, serif !important;
}

/*TOOLTIP*/
div[class$="-Bubble"] {
    width: 225px;
}

.css-1ipblxq {
    width: 250px !important;
    text-align: center !important;
}
/*END TOOLTIP*/

.Dropdown-option {
    margin-left: 10px;
    font-size: 12px;
}

.Dropdown-menu {
    min-height: 100%;
    max-height: 43rem !important;
}

.Dropdown-control {
    border-radius: 9999px !important;
}

.grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
}

.ReactModal__Overlay {
    z-index: 100;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.box {
    @apply bg-black min-w-full h-32 min-h-full rounded;
}

@layer base {
    /* ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        line-height: 25px;
        font-size: 20px;
    }*/

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        line-height: 25px;
    }
    /*li {
        font-size: 20px;
    }*/

    h1 {
        margin: 0 !important;
    }



    .pure-g [class*="pure-u"] {
        font-family: 'Lato', sans-serif !important;
    }

    .image-gallery-slide .image-gallery-description {
        bottom: unset !important;
        width: 100% !important;
        top: 0 !important;
    }

    .image-gallery-image {
        max-width: 650px !important;
        margin: auto !important;
        max-height: 550px !important;
    }

    .promoHeroTitle {
        text-shadow: 3px 3px 15px #0000006C;
    }

    .divBlogStory, .divPromotionBody {
        font-size: 18px;
        font-weight: 300;
        color: #000000;
        letter-spacing: 1px;
        line-height: 25px;
        padding-bottom: 20px !important;
    }
        /* Rarely Used */

        .divBlogStory h1, .divPromotionBody h1 {
            font-size: 40px;
            font-weight: 400;
            color: #022439;
            text-align: center;
            letter-spacing: 1px;
            line-height: 1.25;
            text-transform: uppercase;
            padding-bottom: 25px !important;
        }
        /* Featured Sailings */

        .divBlogStory h2, .divPromotionBody h2 {
            line-height: 1.25;
            font-size: 26px;
            font-weight: 400;
            color: #022439;
            text-transform: uppercase;
            padding-top: 10px !important;
            letter-spacing: 2px;
        }
        /* Sailing Name */

        .divBlogStory h3, .divPromotionBody h3 {
            font-size: 20px;
            font-weight: 700;
            color: #0b829e;
            padding-top: 10px !important;
            line-height: 28px;
        }
        /* Style Available */

        .divBlogStory h4, .divPromotionBody h4 {
            font-size: 18px;
            font-weight: 700;
            color: #0B829E;
            padding-bottom: 18px !important;
            text-align: left;
            line-height: 1.25;
        }
        /* Caption */

        .divBlogStory h5, .divPromotionBody h5 {
            font-size: 12px;
            font-weight: 500;
            color: #929292;
            padding-right: 10px !important;
            text-align: right;
            line-height: 1;
        }
        /* Terms & Conditions */

        .divBlogStory h6, .divPromotionBody h6 {
            font-size: 14px;
            font-weight: 400;
            color: #38383D;
            text-align: left;
            line-height: 1.25;
        }
        /* Style Available */

        .divBlogStory h7, .divPromotionBody h7 {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            letter-spacing: 1px;
            line-height: 1.25;
        }
        /* Paragraph */

        .divBlogStory p, .divPromotionBody p {
            font-size: 18px;
            margin: 8px 0 15px;
            padding: 0;
            line-height: 1.5;
        }
    /* BELOW ARE TEMPLATE FIXES FOR PROMOTIONS/BLOGS*/
    .ItineraryMargin {
        margin: 0px 0px 10px;
    }

    .CaptionMargin {
        margin: 0px 0px 15px;
    }

    .ImageBlock /*  Removing inline css to support global styling. */ {
        display: block;
        width: 100%;
        margin: 0px 0px 8px;
    }

    .CorrectContainer {
        margin: 15px auto; /*  Changed from 50px to 15px  */
        max-width: 920px; /*  Keeps templates within set margin  */
    }


    .ExtraPadding /*  Adding padding. The additional bottom padding helps with responsive spacing. */ {
        padding: 10px 10px 25px;
    }


    .InsertTemplate /*  Styling of instructional text */ {
        display: none;
        visibility: hidden;
    }

    .ButtonCenter {
        text-align: center;
        margin: 20px auto;
    }

    .pure-button {
        background-color: #eb312e !important;
        color: #ffffff !important;
        font-weight: bold !important;
        text-decoration: none !important;
        text-transform: uppercase !important;
    }

        .pure-button:hover {
            color: #ffffff !important;
            background-color: #bc2725 !important;
            transition: 0.3s !important;
        }
    /*Ceros Specific*/
    .ceros {
        padding-top: 64.93% !important;
    }

    @media (max-width: 568px) {
        .ceros {
            padding-top: 147.05% !important;
        }
    }
    /* ABOVE ARE TEMPLATE FIXES FOR PROMOTIONS/BLOGS*/

    .terms p .terms span {
        font-family: 'Lato', Arial, serif !important;
        font-size: 12px;
        font-weight: 400;
        color: #929292;
        letter-spacing: 1px;
        line-height: 1;
        padding-bottom: 10px !important;
    }

    .terms ul {
        list-style-type: initial;
        list-style-position: inside;
    }

    .terms ol {
        list-style-type: decimal;
        list-style-position: inside;
    }

    .terms li {
        padding-bottom: 10px;
    }

    .terms ul ul, .terms ol ul {
        list-style-type: initial;
        list-style-position: inside;
        margin-left: 15px;
    }

    .terms ol ol, .terms ul ol {
        list-style-type: decimal;
        list-style-position: inside;
        margin-left: 15px;
    }

    .divBlogStory img, .divPromotionBody img {
        border-radius: 10px;
    }

    .divBlogStory ul, .divPromotionBody ul, .divBlogStory li, .divPromotionBody li {
        font-size: unset;
        list-style-type: disc;
        margin-left: 10px;
    }

    .pure-img {
        padding: 4px;
    }

    .masonry-item-1-3, .masonry-item-2-3 {
        margin: 5px;
        position: relative;
    }

    .masonry-img {
        padding: 1px;
    }

    .masonry-item-text {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 30px;
        margin: 1px;
        background: #000;
        line-height: 20px;
        min-height: 40px;
        color: #FFF;
    }

    .agencyLocatorFrame {
        height: 125vh;
    }
}

@media only screen and (max-width: 568px) {
    .divBlogStory h1, .divPromotionBody h1 {
        font-size: 30px;
    }

    .divBlogStory h2, .divPromotionBody h2 {
        font-size: 20px;
    }

    .divBlogStory p, .divPromotionBody p {
        font-size: 16px;
    }

    .has-media-on-the-right {
        display: flex !important;
        flex-direction: column-reverse !important;
    }
}

@layer components {
    .hidden-tw {
        @apply hidden
    }
    /* .mceTmpl {
        @apply my-8
    }*/
}